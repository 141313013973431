/*
 * @deprecated
 * THIS IS A LEGACY FILE, DON'T ADD ANYTHING NEW IN IT
 *
 * For new components, use instead app/assets/javascripts/initializers/react/components
 * and use react_component helper in .haml file
 *
 */

import { createElement, lazy } from 'react';

import List from 'hb-react/backoffice/components/List/lazy';
import ReactRenderer from 'hb-react/shared/helpers/ReactRenderer';
import lazyLoadGlobalComponents from 'hb-react/shared/utils/lazyLoadGlobalComponents';
import ThemeProvider from 'hb-react/style/ThemeProvider';

const ListComponents = Object.keys(List).reduce((result, componentName) => {
  result[`List.${componentName}`] = List[componentName];

  return result;
}, {});

window.ReactRenderer = ReactRenderer;

/**
 * @deprecated
 */
const components = {
  ...ListComponents,
  UserList: lazy(
    () => import('hb-react/backoffice/components/user_list/UserList'),
  ),
  ReceiptList: lazy(() => import('hb-react/backoffice/components/ReceiptList')),
  MapboxMap: lazy(() => import('hb-react/frontoffice/components/MapboxMap')),
  SocialPlugin: lazy(
    () => import('hb-react/frontoffice/components/SocialPlugin'),
  ),
  ImageUpload: lazy(
    () => import('hb-react/backoffice/components/DesignSystem/ImageUpload'),
  ),
  Flag: lazy(
    () =>
      import('hb-react/backoffice/components/DesignSystem/ImageUpload/Flag'),
  ),
  ReportedContentPreview: lazy(
    () => import('hb-react/backoffice/components/ReportedContentPreview'),
  ),
  SurveysFormEditor: lazy(
    () => import('hb-react/backoffice/apps/SurveysFormEditor'),
  ),
  SurveysAnalytics: lazy(
    () => import('hb-react/backoffice/components/surveys/SurveysAnalytics'),
  ),
  UserDataApp: lazy(
    () => import('hb-react/backoffice/apps/UserData/UserDataApp'),
  ),
  UserProfileSetupApp: lazy(
    () =>
      import('hb-react/backoffice/apps/UserProfileSetup/UserProfileSetupApp'),
  ),
  EventCustomAttributesApp: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/EventCustomAttributes/EventCustomAttributesApp'
      ),
  ),
  JobCustomAttributesApp: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/JobCustomAttributes/JobCustomAttributesApp'
      ),
  ),
  LinkUrl: lazy(() => import('hb-react/backoffice/components/LinkUrl')),
  SSOAttributesMapping: lazy(
    () => import('hb-react/backoffice/components/SSOAttributesMapping'),
  ),
  VentureCustomAttributesApp: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/VenturesCustomAttributes/VentureCustomAttributesApp'
      ),
  ),
  DonationCampaignFormSetup: lazy(
    () => import('hb-react/backoffice/apps/DonationCampaignFormSetup'),
  ),
  DonationUserFormSetup: lazy(
    () => import('hb-react/backoffice/apps/DonationUserFormSetup'),
  ),
  MembershipUserFormSetup: lazy(
    () => import('hb-react/backoffice/apps/MembershipUserFormSetup'),
  ),
  AddAttendeeApp: lazy(
    () => import('hb-react/backoffice/apps/AddAttendee/AddAttendeeApp'),
  ),
  AddAttendeeRsvp: lazy(
    () => import('hb-react/backoffice/apps/AddAttendeeRsvp'),
  ),
  AttendeeListApp: lazy(() => import('hb-react/backoffice/apps/AttendeeList')),
  DonationsFormApp: lazy(
    () => import('hb-react/backoffice/apps/DonationsForm'),
  ),
  ReportsTab: lazy(
    () => import('hb-react/backoffice/apps/Donations/DonationReportsTabApp'),
  ),
  DonationCampaignReport: lazy(
    () =>
      import('hb-react/backoffice/apps/Donations/DonationCampaignReportApp'),
  ),
  Loader: lazy(() => import('hb-react/shared/components/Loader')),
  CodeEditor: lazy(() => import('hb-react/backoffice/components/CodeEditor')),
  ApprovalsApp: lazy(
    () => import('hb-react/backoffice/apps/Matching/ApprovalsApp'),
  ),
  MatchingApp: lazy(
    () => import('hb-react/backoffice/apps/Matching/MatchingApp'),
  ),
  CustomFormSetup: lazy(
    () => import('hb-react/backoffice/apps/CustomFormSetup'),
  ),
  ContentPreview: lazy(
    () => import('hb-react/shared/components/ContentPreview'),
  ),
  SocialMediaButtonsContainer: lazy(
    () => import('hb-react/backoffice/components/SocialMediaButtonsContainer'),
  ),
  DateTimePicker: lazy(
    () => import('hb-react/shared/components/DateTimePicker'),
  ),
  ThemeProvider: (props) =>
    createElement(ThemeProvider, { theme: 'bo', ...props }),
  DonationFilters: lazy(
    () => import('hb-react/backoffice/apps/Donations/DonationFilters'),
  ),

  // TODO: migrate to react_renderer
  ZoomApiList: lazy(
    () =>
      import('hb-react/backoffice/apps/ExternalApiIntegrations/ZoomApiList'),
  ),
  // TODO: migrate to react_renderer
  TicketCheckinsApp: lazy(
    () => import('hb-react/backoffice/apps/TicketCheckinsApp'),
  ),
  // TODO: migrate to react_renderer
  UniversalTags: lazy(
    () => import('hb-react/backoffice/apps/UniversalTags/UniversalTagsApp'),
  ),
  SuperAdminActivateUsers: lazy(
    () => import('hb-react/backoffice/components/SuperAdminActivateUsers'),
  ),
  UserAutocomplete: lazy(
    () => import('hb-react/backoffice/components/UserAutocomplete'),
  ),
  UserSearch: lazy(() => import('hb-react/shared/components/UserSearch')),
};

lazyLoadGlobalComponents(components);
