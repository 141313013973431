/*
  Usage:
  %div{ data: { behavior: 'image-preview' }}
    %img{ src: '....'}
    %input...
*/

;(function ( $, window, document, undefined ) {

  // Create the defaults once
  var pluginName = "imagePreview",
  defaults = {};

  // The actual plugin constructor
  function Plugin( element, options ) {
    this.element  = element;
    this.$element = $(element);

      // jQuery has an extend method that merges the
      // contents of two or more objects, storing the
      // result in the first object. The first object
      // is generally empty because we don't want to alter
      // the default options for future instances of the plugin
      this.options = $.extend( {}, defaults, options) ;

      this._defaults = defaults;
      this._name = pluginName;

      this.init();
    }

    Plugin.prototype = {

      init: function() {
        var $image_el = this.$element.find('img');
        if ($image_el.length == 0) {
         $image_el = $('<img>').addClass('block');
         this.$element.append($image_el);
        }
        var that = this;
        this.$element.find('input').change(function(event) {
            if (this.files && this.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $image_el.attr('src', e.target.result).attr('height', (that.$element.data('height') || 120));
                    $image_el.removeClass('hide, hidden')
                }
                reader.readAsDataURL(this.files[0]);
            }
        });
      }
    };

  // A really lightweight plugin wrapper around the constructor,
  // preventing against multiple instantiations
  $.fn[pluginName] = function ( options ) {
    return this.each(function () {
      if (!$.data(this, "plugin_" + pluginName)) {
        $.data(this, "plugin_" + pluginName,
          new Plugin( this, options ));
      }
    });
  }

})( jQuery, window, document );

$(function() {
  $('[data-behavior=image-preview]').each(function(index, el) {
    $(this).imagePreview();
  });
});
