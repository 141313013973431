/* eslint-disable jquery/no-ready */
/* eslint-disable no-undef */
$(() => {
  // @TODO
  // Clean select2 deleted tags properly
  // eslint-disable-next-line eqeqeq
  if ($.fn.select2 != undefined) {
    $.fn.select2.amd.require(['select2/selection/search'], (Search) => {
      const oldRemoveChoice = Search.prototype.searchRemoveChoice;

      Search.prototype.searchRemoveChoice = function () {
        // eslint-disable-next-line prefer-rest-params
        oldRemoveChoice.apply(this, arguments);
        this.$search.val('');
      };
    });
  }

  AlumniConnect.Helpers.JS.initInputs();

  setTimeout(() => {
    AlumniConnect.Helpers.JS.initRemoteForm();
  });
});
