/* eslint-disable jquery/no-slide */
/* eslint-disable jquery/no-class */
/* eslint-disable jquery/no-fade */
/* eslint-disable no-undef */
/* eslint-disable jquery/no-ready */

window.BONotification = function () {
  if ($('.bo-notification.unvisible').length > 0) {
    $('.bo-notification')
      .removeClass('unvisible')
      .addClass('bo-notification--animation');

    setTimeout(() => {
      $('.bo-notification').fadeTo(500, 0).slideUp();
      $('.bo-notification').remove();
    }, 5000);
  }
};

$(document).on('pageshow', (event) => {
  if (
    event.persisted ||
    (window.performance && window.performance.navigation.type === 2)
  ) {
    return;
  }

  window.BONotification();
});

$(() => {
  $(document).on('click', '[data-behavior=close-notif]', function () {
    // eslint-disable-next-line jquery/no-parent
    $(this).parent().fadeTo(500, 0).slideUp();

    return false;
  });
});
