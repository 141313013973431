const ANIMATION_DURATION = 300;
const ANIMATION_DELAY = 300;

const largeWidth = '260px';
const smallWidth = '52px';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.bo-page-header');
  const content = document.querySelector('.has-bo-page-header');
  const sideBar = document.querySelector('.bo-sidebar');

  let timeoutId = null;

  if (!sideBar || !header || !content) {
    return;
  }

  const isSideBarLarge = sideBar.dataset.size === 'large';
  const isSideBarSmall = sideBar.dataset.size === 'small';
  const isSideBarHidden = sideBar.dataset.size === 'hidden';

  const initialWidth = isSideBarLarge
    ? largeWidth
    : isSideBarSmall
    ? smallWidth
    : '0px';

  header.style.transition = 'margin-left ease-in-out';
  content.style.transition = 'margin-left ease-in-out';
  sideBar.style.transition = 'all ease-in-out';

  const setAnimationDuration = (duration = ANIMATION_DURATION) => {
    header.style.transitionDuration = `${duration}ms`;
    content.style.transitionDuration = `${duration}ms`;
    sideBar.style.transitionDuration = `${duration}ms`;
  };

  const setLargeWidth = (delay = ANIMATION_DELAY) =>
    window.requestAnimationFrame(() => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        header.style.marginLeft = largeWidth;
        content.style.marginLeft = largeWidth;
        sideBar.style.width = largeWidth;
        sideBar.style.minWidth = largeWidth;
        sideBar.style.maxWidth = largeWidth;
      }, delay);
    });

  const setInitialWidth = (delay = ANIMATION_DELAY) =>
    window.requestAnimationFrame(() => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        header.style.marginLeft = initialWidth;
        content.style.marginLeft = initialWidth;
        sideBar.style.width = initialWidth;
        sideBar.style.minWidth = initialWidth;
        sideBar.style.maxWidth = initialWidth;
      }, delay);
    });

  if (!isSideBarHidden) {
    setAnimationDuration(0);
  }

  setInitialWidth(0);

  if (isSideBarHidden) {
    return;
  }

  if (!isSideBarLarge) {
    sideBar.addEventListener('mouseover', () => {
      setAnimationDuration();
      setLargeWidth();
    });
    sideBar.addEventListener('mouseleave', () => {
      setAnimationDuration();
      setInitialWidth();
    });
  }
});
