/* eslint-disable import/no-webpack-loader-syntax, import/no-dynamic-require */

require(`!file-loader?context=${__dirname}&outputPath=vendor/ckeditor/&name=[path][name].[ext]!./config.js`);

require.context(
  `!file-loader?context=${__dirname}&outputPath=vendor/ckeditor/&name=[path][name].[ext]!./plugins/`,
  true,
  /.*/,
);

require.context(
  `!file-loader?context=${__dirname}&outputPath=vendor/ckeditor/&name=[path][name].[ext]!./lang/`,
  true,
  /.*/,
);

require.context(
  `!file-loader?context=${__dirname}&outputPath=vendor/ckeditor/&name=[path][name].[ext]!./skins/`,
  true,
  /.*/,
);

require(`!file-loader?context=${__dirname}&outputPath=vendor/ckeditor/&name=[path][name].css!sass-loader!./overide_default_ckeditor.scss`);

require('./pathConfig');
require('./ckeditor');
require('./cacheExpiration');
require('./styles');
require('./adapters/jquery');
