/* eslint-disable */

const defaults = {
  animate: true,
};

const forcedOptions = {
  animate: window.__HB_ENV__.ENVIRONMENT !== 'test',
};

(function ($) {
  $.fn.toggleFadeHorizontal = function (showOrHide, options) {
    var elt = this;

    options = Object.assign(defaults, options, forcedOptions);

    elt.on('animationend', function () {
      elt.addClass('no-animation');
    });
    elt
      .toggleClass('no-animation', !options.animate)
      .toggleClass('fade-out--horizontal', !showOrHide)
      .toggleClass('fade-in--horizontal', showOrHide);
  };

  $.fn.toggleFadeVertical = function (showOrHide, options) {
    var elt = this;
    options = Object.assign(defaults, options, forcedOptions);

    elt.on('animationend', function () {
      elt.addClass('no-animation');
    });
    elt
      .toggleClass('no-animation', !options.animate)
      .toggleClass('fade-in--vertical', showOrHide)
      .toggleClass('fade-out--vertical', !showOrHide);
  };
})(jQuery);
