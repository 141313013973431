/* eslint-disable jquery/no-attr */
/* eslint-disable jquery/no-class */
/* eslint-disable jquery/no-closest */
/* eslint-disable jquery/no-data */
/* eslint-disable jquery/no-toggle */
/* eslint-disable no-undef */
import { preventBouncingViewportIssueOniOS } from 'helpers/preventBouncingViewportIssueOniOS';
import 'locales/overrideDatepickerLocaleDefaults';
import 'locales/loadMoment';

// Polyfill :focus-visible
import 'focus-visible';

// CSS entry point
require('legacy/stylesheets/backoffice.scss');

// Libraries
require('jquery');
require('jquery-ujs');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/widgets/autocomplete');
// Axios to replace $.ajax calls
window.axios = require('hb-react/shared/utils/axios').axiosInstance;
window.toast = require('@hivebrite/design-system/Toast').toast;

// Gems
require('@nathanvda/cocoon');

require('select2');
require('moment');
window.moment = require('moment-timezone');
require('libs/bootstrap/js/collapse');
require('libs/bootstrap/js/dropdown');
require('libs/bootstrap/js/tooltip');
require('libs/bootstrap/js/transition');

require('libs/ckeditor');

// jQuery plugins
// eslint-disable-next-line import/no-extraneous-dependencies
require('webui-popover');
require('jquery-serializejson');
require('responsive-slides/responsiveslides');
require('jquery.are-you-sure');
require('blueimp-file-upload/js/jquery.iframe-transport');
require('blueimp-file-upload/js/jquery.fileupload');

// jQuery vendored plugins
require('libs/jquery.datetimepicker');
require('libs/jquery.tablesorter');
require('legacy/javascripts/plugins/animations');
require('legacy/javascripts/plugins/image_preview');
require('libs/jquery.fancybox');
require('legacy/javascripts/plugins/kit_fancybox_global');
require('libs/jquery.ui.network_search');

window.AlumniConnect = {
  Helpers: {
    Global: {},
  },
};

// Self exposing on window
require('helpers/forms');
require('helpers/global');
require('initializers/hammerhistory');
require('initializers/pickplace');
require('legacy/javascripts/backoffice/initializers/clipboard');
require('legacy/javascripts/backoffice/initializers/flashs');
require('legacy/javascripts/backoffice/initializers/forms');
require('legacy/javascripts/backoffice/initializers/images');
require('legacy/javascripts/backoffice/initializers/menu');
require('legacy/javascripts/backoffice/initializers/tables');

import('legacy/javascripts/backoffice/initializers/tabs_v2');
import('legacy/javascripts/backoffice/initializers/handlebars');
import('legacy/javascripts/backoffice/initializers/datepicker');
import('legacy/javascripts/backoffice/initializers/sortablejs');

// Objects
require('legacy/javascripts/objects/experiences');
require('legacy/javascripts/objects/educations');

// Components
require('react');
require('react-dom');
require('backoffice_components');

// Initializers
require('initializers/datepicker');
require('initializers/react');

// eslint-disable-next-line jquery/no-ready
$(document).ready(() => {
  function initLayout() {
    // eslint-disable-next-line jquery/no-show
    $('.backoffice-content').show();
    $('#loader').remove();

    // Check if current BO page is render inside an iFrame for BO preview inside the FO for group Admin
    // Do not hide the menu anyway on Cypress iFrame.
    if (window.self !== window.top && !window.Cypress) {
      const sidebar = document.getElementsByClassName('bo-sidebar')[0];
      const header = document.getElementsByClassName('bo-page-header')[0];

      // elements are not present on the admin login page
      if (sidebar) sidebar.remove();
      if (header) header.setAttribute('style', 'margin-left: 0');
    }
  }

  initLayout();

  // Handle popups
  $(document).on('click', '[data-behavior=popup]', function (e) {
    e.preventDefault();
    const height = $(this).data('height') || 'auto';
    const width = $(this).data('width') || 'auto';

    $(this).fancybox({
      width,
      height,
      autoDimensions: false,
      autoSize: false,
      helpers: {
        overlay: { closeClick: false },
      },
    });
    $(this).attr('data-behavior', 'popupInitialized');
    $(this).click();
  });

  $(document).on('click', '[data-behavior="toggle"]', function () {
    $($(this).data('el')).toggle();
    $(this).closest('li').toggleClass('active');
  });

  $('body').tooltip({
    selector: '[data-behavior=tooltip], [data-toggle=tooltip],[rel=tooltip]',
    html: true,
  });

  $('[data-toggle=popover]').webuiPopover({ closeable: true });

  $.fn.select2.defaults.set('language', window.__HB_LOCALE__);

  $('[data-behavior=datetimepicker]').datetimepicker();

  // Preventing iOS Textbox Auto Zooming and ViewPort Sizing
  preventBouncingViewportIssueOniOS();
});
