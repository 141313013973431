import * as errorReporting from 'hb-react/shared/utils/errorReporting';

const overrideDatepickerLocaleDefaults = () => {
  try {
    if (I18n.locale === 'es') {
      $.datepicker.setDefaults(
        $.extend($.datepicker.regional[I18n.locale], {
          dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        }),
      );
    }
  } catch (e) {
    errorReporting.captureException(e);
  }
};

export default overrideDatepickerLocaleDefaults;
