document.addEventListener('DOMContentLoaded', () => {
  $('[data-behavior=table-search]').on('keyup', () => {
    const searchString = this.value.toLocaleLowerCase();
    const tableEl = $($(this).data('table-id'));
    const colName = $(this).data('colname');

    if (searchString === '') {
      tableEl.find('tr').show();
    } else {
      tableEl.find(`tr td[data-name=${colName}]`).each(function () {
        if (this.textContent.toLocaleLowerCase().indexOf(searchString) === -1) {
          $(this).closest('tr').hide();
        } else {
          $(this).closest('tr').show();
        }
      });
    }
  });
});

window.tablesorterTextExtraction = function (node) {
  if ($(node).data('sort') !== undefined) {
    return $(node).data('sort');
  }

  return $(node).text();
};
