(function( $ ) {

  $.widget("ui.networks_search", {
    options: {
      minLength: 1
    },

    _create: function() {
      var that = this;
      this.element.autocomplete({
        noResults: '',
				that: this,
        source: "/search/networks.js",
        minLength: this.options.minLength,
        focus: function(event, ui) {
          that.element.val(ui.item.title);
          return false;
        },
        select: function(event, ui) {
          if (that.options.redirect == undefined || that.options.redirect == true) {
						that.element.val("");
            window.location = [
              window.location.protocol, '//',
              ui.item.identifier, '.',
              window.location.hostname, ':',
              window.location.port
            ].join('');
					}
				return false;
        }
      }).data("ui-autocomplete")._renderItem = function(ul, item) {
        var html = "<a class=\"networks-search-item\">"
        //html += "<img class=\"avatar\" src='" + item.photo_url + "'/>"
        html += "<div class=\"infos\">"
        html += item.title
        html += "</div>"
        html += "</a>"
        return $("<li></li>" )
          .data("item.autocomplete", item)
          .append(html)
          .appendTo(ul);
      };;
    }
  });

})( jQuery );
